import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getName } from 'country-list';
import { generateDate } from '../helpers/commonFunctions';
import Seo from '../components/seo';
import amNewsHero from '../images/am-news-sm.jpg';
import HtmlParser from '../helpers/htmlParser';
import { EventPromo } from '../components/eventPromo';
import { GatsbyImage } from 'gatsby-plugin-image';

import CustomBreadcrumb from '../components/CustomBreadcrumb';

const Event = ({ data, pageContext, location }) => {
    function capitalize(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    const pageRawTitle = location.pathname;
    const lastItem = pageRawTitle.substring(pageRawTitle.lastIndexOf('/') + 1);
    const replace = lastItem.replace(/-/g, ' ');
    const pageTitle = capitalize(replace);

    const {
        breadcrumb: { crumbs }
    } = pageContext;
    const events = data.drupal.nodeQuery.entities[0];
    const { body, entityLabel, fieldAddress, fieldPressCategory, fieldEventDate, fieldMedia } =
        events;

    let alt = '';

    let startDate = generateDate(fieldEventDate.startDate, 'long');
    let endDate;

    if (fieldEventDate?.endDate) {
        endDate = generateDate(fieldEventDate.endDate, 'long');
    }

    return (
        <Layout>
            <Seo title={pageTitle} />
            <div className='hero__image hero__image--news'>
                <figure>
                    <img src={amNewsHero} alt='ActiveMedia hero'></img>
                </figure>
                <div className='hero__pages'>
                    <div className='hero__news--center'>
                        <h1>News & Events</h1>
                        <p>
                            <span>Stay Up to Date on The Latest News & Events.</span>
                        </p>
                    </div>
                </div>
            </div>
            <CustomBreadcrumb crumbs={crumbs} />
            <div className='main__container press-main__container'>
                <div className='single-blog__container--bg'>
                    <div className='single-blog__container'>
                        <article className='single-blog__box--container'>
                            <div className='single-blog__box--elements'>
                                <div className='single-blog__box--elements--text'>
                                    <div className='single-blog__box--elements--text--header'>
                                        <div className='single-blog__box--elements--text--type single-blog__type--event'>
                                            <FontAwesomeIcon icon={'calendar-alt'} />
                                        </div>
                                    </div>
                                    <div className='single-blog__box--body'>
                                        <div className='single-blog__box--body--title'>
                                            <h2>{entityLabel}</h2>
                                        </div>
                                        <div className='single-blog__box--body--info'>
                                            <div className='location'>
                                                <FontAwesomeIcon icon={'map-marker-alt'} />
                                                {fieldAddress?.locality &&
                                                fieldAddress?.countryCode ? (
                                                    <HtmlParser
                                                        wrapperClass='location--string'
                                                        html={
                                                            fieldAddress?.locality +
                                                            ', ' +
                                                            fieldAddress?.countryCode
                                                                ? getName(fieldAddress?.countryCode)
                                                                : ''
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                            <div className='date'>
                                                <FontAwesomeIcon icon={'calendar'} />
                                                {fieldEventDate?.startDate !==
                                                fieldEventDate?.endDate ? (
                                                    <HtmlParser
                                                        wrapperClass='date--string'
                                                        html={startDate + ' to ' + endDate}
                                                    />
                                                ) : (
                                                    <HtmlParser
                                                        wrapperClass='date--string'
                                                        html={startDate}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className='single-blog__box--body--categories'>
                                            {fieldPressCategory.map((category) => (
                                                <div className='single-blog__box--body--category'>
                                                    <span>{category.entity.title}</span>
                                                </div>
                                            ))}
                                        </div>
                                        {/*<Img
                      alt={alt}
                      fluid={fieldMedia?.[0]?.notStyledImage?.childImageSharp?.fluid}
                    />*/}
                                        <GatsbyImage
                                            alt={alt}
                                            image={
                                                fieldMedia?.[0]?.notStyledImage?.childImageSharp
                                                    ?.gatsbyImageData
                                            }
                                        />
                                        <div className='single-blog__box--body--text--wrapper'>
                                            <div className='single-blog__box--body--text'>
                                                <HtmlParser
                                                    html={
                                                        body?.processed !== undefined
                                                            ? body.processed
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
            <div className='press-promo-container'>
                <div className='press-promo-container-box'>
                    <div className='press-fafaicon-container'>
                        <div className='press-fafaicon-box'>
                            <FontAwesomeIcon icon='bullhorn' />
                        </div>
                        <h3>Read more</h3>
                    </div>
                    <EventPromo />
                    <div className='press-button'>
                        <Link to='/press'>News & Events</Link>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

Event.propTypes = {
    data: PropTypes.object.isRequired
};

export const query = graphql`
    query ($EventId: [String]) {
        drupal {
            nodeQuery(limit: 10000, filter: { conditions: { field: "uuid", value: $EventId } }) {
                count
                entities {
                    entityUuid
                    entityId
                    entityLabel
                    entityBundle
                    ... on Drupal_NodePageEvent {
                        nid
                        uuid
                        body {
                            value
                            processed
                        }
                        fieldMedia {
                            entity {
                                uuid
                                url
                            }
                            notStyledImage {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                            url
                        }
                        fieldAddress {
                            locality
                            countryCode
                            addressLine2
                            addressLine1
                            additionalName
                        }
                        fieldPressCategory {
                            entity {
                                title
                            }
                        }
                        fieldEventDate {
                            startDate
                            endDate
                        }
                    }
                }
            }
        }
    }
`;

export default Event;
