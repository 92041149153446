const generateDate = (date = Date.now(), format = "long") => {
    let dateObj = new Date(date);
    let year = dateObj.getFullYear();
    let month = new Intl.DateTimeFormat('en-US', { month: 'numeric' }).format(dateObj);
    let monthLong = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(dateObj);
    let dayDate = dateObj.getUTCDate();
    let dayWeek = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(dateObj);
    let dayWeekLong = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(dateObj);
    switch (format) {
        case "long":
            return String(dayWeekLong + ", " + dayDate + " " + monthLong + ", " + year);
        case "short":
            return String(month + "/" + dayDate + "/" + year);
        default:
            return String(dayWeek + ", " + dayDate + " " + month + ", " + year);
    }
}

export { generateDate };